import React from 'react';
import ReactDOM from 'react-dom/client';

/** Content */
import ThemeProvider from './utility/context/ThemeProvider';

import { Provider } from 'react-redux';
import generateStore from './redux/store';

import { ToastContainer } from 'react-toastify';

/** Lazy load app */
const LazyApp = React.lazy(() => import("./App"))
const store = generateStore()

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(	
	<Provider store={store}>
		<ThemeProvider>
			<LazyApp />		
			<ToastContainer />
		</ThemeProvider>
	</Provider>	
);