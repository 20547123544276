import { createStore , combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
//import {composeWithDevTools} from 'redux-devtools-extension'

import appReducer from './ducks/appDucks';
import authReducer from './ducks/authDucks';
import walletReducer from './ducks/walletDucks';

import { initCheck as initCheckAuth } from './ducks/authDucks';
import { initCheck as initCheckWallet } from './ducks/walletDucks'

const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    wallet: walletReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

    initCheckAuth()(store.dispatch);
    initCheckWallet()(store.dispatch);

    return store;
}