const initialState = {
    isLoginModalOpen: false,
    isRegisterModalOpen: false,
    isForgotPasswordModalOpen: false,
    isDepositModalOpen: false,
    isWithdrawModalOpen: false,
    isRaffleItemOpen: false,
    raffleSelected: null,
}

/** Types */
const APP_TOGGLE_LOGIN_MODAL = 'APP_TOGGLE_LOGIN_MODAL';
const APP_TOGGLE_REGISTER_MODAL = 'APP_TOGGLE_REGISTER_MODAL';
const APP_TOGGLE_FORGOT_PASSWORD_MODAL = 'APP_TOGGLE_FORGOT_PASSWORD_MODAL';
const APP_TOGGLE_DEPOSIT_MODAL = 'APP_TOGGLE_DEPOSIT_MODAL';
const APP_TOGGLE__WITHDRAW_MODAL = 'APP_TOGGLE_WITHDRAW_MODAL';
const APP_TOGGLE_RAFFLE_ITEMS_MODAL = 'APP_TOGGLE_RAFFLE_ITEMS_MODAL';

/** Reducer */
export default function pokeReducer(state = initialState, action) {
    switch (action.type) {
        case APP_TOGGLE_LOGIN_MODAL:
            return {...state, isLoginModalOpen: action.payload}
        case APP_TOGGLE_REGISTER_MODAL:
            return {...state, isRegisterModalOpen: action.payload}
        case APP_TOGGLE_FORGOT_PASSWORD_MODAL:
            return {...state, isForgotPasswordModalOpen: action.payload}
        case APP_TOGGLE_DEPOSIT_MODAL:
            return {...state, isDepositModalOpen: action.payload}
        case APP_TOGGLE__WITHDRAW_MODAL:
            return {...state, isWithdrawModalOpen: action.payload}
        case APP_TOGGLE_RAFFLE_ITEMS_MODAL:
            return {...state, isRaffleItemOpen: action.payload.status, raffleSelected: action.payload.raffle}
        default:
            return state
    }
}

/** Actions */
export const openLoginModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE_LOGIN_MODAL,
        payload: true
    })
}

export const closeLoginModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE_LOGIN_MODAL,
        payload: false
    })
}

export const openRegisterModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE_REGISTER_MODAL,
        payload: true
    })
}

export const closeRegisterModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE_REGISTER_MODAL,
        payload: false
    })
}

export const openForgotPasswordModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE_FORGOT_PASSWORD_MODAL,
        payload: true
    })
}

export const closeForgotPasswordModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE_FORGOT_PASSWORD_MODAL,
        payload: false
    })
}

export const openDepositModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE_DEPOSIT_MODAL,
        payload: true
    })
}

export const closeDepositModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE_DEPOSIT_MODAL,
        payload: false
    })
}

export const openWithdrawModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE__WITHDRAW_MODAL,
        payload: true
    })
}

export const closeWithdrawModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE__WITHDRAW_MODAL,
        payload: false
    })
}

export const openRaffleItemsModal = (raffle) => dispatch => {    
    dispatch({
        type: APP_TOGGLE_RAFFLE_ITEMS_MODAL,
        payload: {
            status: true,
            raffle: raffle
        }
    })
}

export const closeRaffleItemsModal = () => dispatch => {
    dispatch({
        type: APP_TOGGLE_RAFFLE_ITEMS_MODAL,
        payload: {
            status: false,
            raffle: null
        }
    })
}