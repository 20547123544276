import axios from 'axios';
import { API_BASE_URL } from '@src/config/constants';
import { useDispatch } from 'react-redux';
import { logout } from '@src/redux/ducks/authDucks';
import { toast } from 'react-toastify';

const axiosClient = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
});

if(localStorage.getItem('access_token')) {
    axiosClient.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('access_token')}`}
}

axiosClient.interceptors.response.use(
    response => response,
    error => {
        if(error.response.data && error.response.data.error !== undefined) {
            toast.error(error.response.data.error)
        }
        
        if (error.response.status === 400) {
            toast.error(error.response.data);
        } else if (error.response.status === 401) {
            const dispatch = useDispatch();
            dispatch(logout());
        } else if (error.response.status === 422) {
            for (let key in error.response.data.errors) {
                toast.error(`${error.response.data.errors[key]}`);
            }
        }
        
        return Promise.reject(error);
    }
);

export default axiosClient