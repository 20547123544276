export const getShortWalletAddress = (address = '') => {
    return `${address.substring(0, 6)}.....${address.substring((address.length - 6), address.length)}`
}

export const getMetamaskErrorMessage = (error) => {
    switch (error.code) {
        case 'ACTION_REJECTED':
            return 'La operación ha sido cancelada por el usuario.';
        case 'UNPREDICTABLE_GAS_LIMIT':
            return 'La transacción no se realizará. El límite de gas está establecido en un valor demasiado bajo.';
        case 4200:
            return 'Unsupported method: The requested method is not supported by MetaMask.';
        case 4900:
            return 'Disconnected: MetaMask is disconnected from the blockchain.';
        case 4901:
            return 'Chain disconnected: MetaMask is connected to an unsupported chain.';            
        default:
            return 'Se ha producido un error desconocido: ' + error;
    }
}