import axiosClient from "@src/config/api";

export const getLoggedUserInfo = async () => {
    try {
        const response = await axiosClient.post('auth/me');        
        return response.data;
    } catch (error) {
        throw new Error(error);
    }            
}

export const updateUserProfile = async (data) => {
    try {        
        const response = await axiosClient.post('site/actualizar-perfil', data);        
        return response.data;
    } catch (error) {
        throw new Error(error);
    }            
}