import { createContext, useEffect, useState } from 'react'

export const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {

    const LIMIT_HEIGHT_SCROLL = 300;
    
    const [themeOptions, setThemeOptions] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isScrolled, setIsScrolled] = useState(false);

	const initThemeOptions = () => {        
        setThemeOptions({
            bannerSectionHeight: (document.querySelector('.banner')) ? document.querySelector('.banner').offsetHeight : 0,
            headerSectionHeight: (document.querySelector('.header')) ? document.querySelector('.header').offsetHeight : 0,
            howToWorkSectionHeight: (document.querySelector('.how-to-works')) ? document.querySelector('.how-to-works').offsetHeight : 0
        })
	}

    const navbarTogglerButton = () => {
		const mobileWrapper = document.querySelector('.mobile-navbar-wrapper');
		
		mobileWrapper.classList.toggle('open');
		mobileWrapper.querySelector('.mainmenu').classList.toggle('open');
		mobileWrapper.querySelector('.navbar-toggler').classList.toggle('close-btn');
	}

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

	useEffect(() => {
		setTimeout(() => {
            initThemeOptions();
			setIsLoading(false);
		}, 1000);
	}, [])

    useEffect(() => {
        const handleScroll = (e) => {
            setIsScrolled((window.scrollY > LIMIT_HEIGHT_SCROLL) ? true : false)
        };
    
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
	
	return (
		<ThemeContext.Provider value={{ themeOptions, isLoading, isScrolled, initThemeOptions, goToTop, navbarTogglerButton }}>
			{children}
		</ThemeContext.Provider>
	)
}

export default ThemeProvider