import axiosClient from "@src/config/api";

export const transactions = async () => {
    try {
        const response = await axiosClient.get('site/operaciones/transacciones');
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}

export const statistics = async () => {
    try {
        const response = await axiosClient.get('site/operaciones/estadisticas');
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}

export const deposit = async data => {
    try {
        const response = await axiosClient.post('site/operaciones/depositar-fondos', data);
        return response.data;
    } catch (error) {
        throw new Error(error)
    }
}

export const withdraw = async data => {
    try {
        const response = await axiosClient.post('site/operaciones/retirar-fondos', data);
        return response.data;
    } catch (error) {
        throw new Error(error)
    }
}

export const buy = async data => {
    try {
        const response = await axiosClient.post('site/operaciones/compra-numeros-rifa', data);
        return response.data;
    } catch (error) {
        throw new Error(error)
    }
}