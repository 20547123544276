import axiosClient from '@src/config/api';
import { ethers } from "ethers";
import { toast } from 'react-toastify';

import { MAIN_TARGET_WALLET } from '@src/config/constants';
import blockchains from '@src/config/blockchains';
import { getMetamaskErrorMessage } from '@src/utility/utils';

import { closeDepositModal, closeWithdrawModal } from '@src/redux/ducks/appDucks';
import { refresh } from '@src/redux/ducks/authDucks';
import { deposit as depositAPI, withdraw as withdrawAPI } from '@src/services/operations.service';

const initialState = {
    provider: null,
    account: null,    
    contract: null,
    lastTransaction: null,
}

/** Types */
const WALLET_SET_PROVIDER = 'WALLET_SET_PROVIDER';
const WALLET_SET_CONTRACT = 'WALLET_SET_CONTRACT';
const WALLET_CONNECT_SUCCESS = 'WALLET_CONNECT_SUCCESS';
const WALLET_DISCONNECT_SUCCESS = 'WALLET_DISCONNECT_SUCCESS';
const WALLET_TRANSACTION_COMPLETED = 'WALLET_TRANSACTION_COMPLETED';

/** Reducer */
export default function pokeReducer(state = initialState, action) {
    switch (action.type) {
        case WALLET_SET_PROVIDER:
            return {...state, provider: action.payload}
        case WALLET_SET_CONTRACT:
            return {...state, contract: action.payload}
        case WALLET_CONNECT_SUCCESS:
            return {...state, account: action.payload.account, contract: action.payload.contract}
        case WALLET_DISCONNECT_SUCCESS:
            return {...state, account: null, balance: null, chainId: null }
        case WALLET_TRANSACTION_COMPLETED:
            return {...state, lastTransaction: action.payload };
        default:
            return state
    }
}

/** Actions */
export const initCheck = () => async (dispatch) => {    
    const provider = await new ethers.providers.Web3Provider(window.ethereum);    

    dispatch({
        type: WALLET_SET_PROVIDER,
        payload: provider
    });

    if(localStorage.getItem('account')) {
        dispatch(connect());
    }
}

export const connect = () => async (dispatch, getState) => {
    try {

        if(!window.ethereum) return;

        const provider = getState().wallet.provider;
        const accounts = await provider.send("eth_requestAccounts", []);
    
        if(accounts) {
            const { chainId } = await provider.getNetwork();
            const blockchain = blockchains[chainId];

            if(blockchain) {
                const contract = new ethers.Contract(blockchain.tokens.usdt.contract, blockchain.tokens.usdt.abi, provider.getSigner());

                if(localStorage.getItem('access_token')) { 
                    axiosClient.post('site/operaciones/conectar', { address: accounts[0] }).then(response => { 
                        if(response.data.status === 'OK') { 
                            localStorage.setItem('account', accounts[0]);
                        } else {
                            alert('Ha ocurrido un error al intentar loguearse en el sistema');
                        }
                    })
                }

                dispatch({
                    type: WALLET_CONNECT_SUCCESS,
                    payload: {
                        account: accounts[0],
                        contract: contract
                    }
                });                
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export const disconnect = () => (dispatch) => {
    dispatch({
        type: WALLET_DISCONNECT_SUCCESS    
    });

    localStorage.removeItem('account');
}

export const deposit = (data) => async (dispatch, getState) => {
    try {
        const { amount } = data;        
        const transaction = await getState().wallet.contract.transfer(MAIN_TARGET_WALLET, ethers.utils.parseEther(amount));
        
        //Close login form modal
        dispatch(closeDepositModal());

        toast.success(`Estamos procesando la operación lo cual se estará realizando en segundo plano. Al momento de confirmar la transacción, se le notificará por este medio`);

        const receipt = await transaction.wait();

        if(!receipt.status) {
            toast.error(`Hubo un problema al depositar los fondos seleccionados`);
            return;
        }              

        const response = await depositAPI({
            transaction: receipt.transactionHash,
            amount
        });

        if(response.status === 'OK') {
            //Update last transaction
            dispatch({
                type: WALLET_TRANSACTION_COMPLETED,
                payload: response
            });

            //Refresh user data
            dispatch(refresh());
            
            toast.success(`${amount} depositados correctamente. Pronto serán abonados en sus creditos de la plataforma`);            
        }

        return;
    } catch (error) {
        console.log(error.code);
        toast.error(`MetaMask error: ${getMetamaskErrorMessage(error)}`);
    }
}

export const withdraw = (data) => async (dispatch, getState) => {
    try {
        const { amount } = data;

        const response = await withdrawAPI({
            transaction: '000',
            amount
        });

        if(response.status === 'OK') {
            //Close login form modal
            dispatch(closeWithdrawModal());
            
            //Update last transaction
            dispatch({
                type: WALLET_TRANSACTION_COMPLETED,
                payload: response
            });

            //Refresh user data
            dispatch(refresh());

            toast.success(response.message);

            return;
        }

    } catch (error) {
        toast.error(`MetaMask error: ${getMetamaskErrorMessage(error)}`);
    }
}