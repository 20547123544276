export const APP_NAME = process.env.REACT_APP_NAME;

export const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

//export const API_BASE_URL = 'http://localhost:8000/api/';

export const API_BASE_URL = 'https://intranet.criptorifa.cl/api/';

export const MAIN_TARGET_WALLET = '0x3cE68D4597E2294abac27283A8D6CFd720fB4823';

export const KEY_BLOCKCHAIN_BNB = 56;