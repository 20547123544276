import axiosClient from '@src/config/api';
import { API_BASE_URL } from '@src/config/constants';
import { toast } from 'react-toastify';

import { closeForgotPasswordModal, closeLoginModal, closeRegisterModal } from '@src/redux/ducks/appDucks';
import { getLoggedUserInfo } from '@src/services/auth.service';

const initialState = {
    loggedIn: false, 
    accessToken: null,
    user: null
}

/** Types */
const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FALURE';
const AUTH_REFRESH_USER = 'AUTH_REFRESH_USER';
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

/** Reducer */
export default function pokeReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_LOGIN_SUCCESS:
            return {...state, loggedIn: true, accessToken: action.payload.access_token, user: action.payload.user}
        case AUTH_LOGIN_FAILURE:
            return {...state, loggedIn: false, accessToken: null, tokenType: null, user: null}
        case AUTH_REFRESH_USER:
            return {...state, user: action.payload}
        case AUTH_LOGOUT_SUCCESS:
            return {...state, loggedIn: false, accessToken: null, tokenType: null, user: null}
        default:
            return state
    }
}

/** Actions */
export const initCheck = () => async (dispatch) => {
    try {
        if(localStorage.getItem('user')) {
            //const data = await getLoggedUserInfo();
            const data = JSON.parse(localStorage.getItem('user'));

            dispatch({
                type: AUTH_LOGIN_SUCCESS,
                payload: {
                    user: data
                }
            });
        }
    } catch (error) {
        dispatch({
            type: AUTH_LOGIN_FAILURE
        });
    }
}

export const login = (credentials) => (dispatch) => {
    axiosClient.get(`${API_BASE_URL}sanctum/csrf-cookie`)
        .then(() => {
            axiosClient.post('auth/login', credentials)
                .then(response => { 
                    if(response.data.status === 'OK') {
                        dispatch({
                            type: AUTH_LOGIN_SUCCESS,
                            payload: response.data
                        });

                        //Set data in local storage
                        localStorage.setItem('access_token', response.data.token);
                        localStorage.setItem('user', JSON.stringify(response.data.user));

                        //Set Bearer token in axiosClient instance
                        axiosClient.defaults.headers.common = {'Authorization': `Bearer ${response.data.token}`}

                        toast.success(response.data.message);

                        //Close login form modal
                        dispatch(closeLoginModal());
                    } else {
                        toast.error('Ha ocurrido un error al intentar loguearse en el sistema');
                    }
                })
        })
}

export const register = (data) => (dispatch) => {
    axiosClient.get(`${API_BASE_URL}sanctum/csrf-cookie`)
        .then(() => {
            axiosClient.post('auth/register', data)
                .then(response => {                    
                    if(response.data.status === 'OK') {
                        dispatch({
                            type: AUTH_LOGIN_SUCCESS,
                            payload: response.data
                        });

                        //Set data in local storage
                        localStorage.setItem('access_token', response.data.token);
                        localStorage.setItem('user', JSON.stringify(response.data.user));

                        //Set Bearer token in axiosClient instance
                        axiosClient.defaults.headers.common = {'Authorization': `Bearer ${response.data.token}`}

                        toast.success(response.data.message);

                        //Close register form modal
                        dispatch(closeRegisterModal());
                    } else {
                        toast.error('Ha ocurrido un error al intentar registrarse en el sistema');
                    }
                })
        })
}

export const forgotPassword = (data) => (dispatch) => {
    axiosClient.get(`${API_BASE_URL}sanctum/csrf-cookie`)
        .then(() => {
            axiosClient.post('auth/forgot-password', data)
                .then(response => {     
                    toast.success(response.data.message);

                    //Close register form modal
                    dispatch(closeForgotPasswordModal());
                }).catch(error => {                    
                    if(Object.keys(error.response.data).length > 0) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error('Ha ocurrido un error al intentar recuperar la contraseña');
                    }
                })
        })
}

export const refresh = () => async (dispatch) => {
    try {
        const data = await getLoggedUserInfo();

        //Refresh user info in local storage
        localStorage.setItem('user', JSON.stringify(data));

        dispatch({
            type: AUTH_REFRESH_USER,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: AUTH_LOGOUT_SUCCESS
        });
    }    
}

export const logout = () => async (dispatch) => {
    try {
        const response = await axiosClient.post('auth/logout');

        if(response.data.status === 'OK') {
            dispatch({
                type: AUTH_LOGOUT_SUCCESS
            });

            localStorage.removeItem('access_token');
            localStorage.removeItem('user');

            toast.success(response.data.message);
        }
    } catch (error) {
        dispatch({
            type: AUTH_LOGIN_FAILURE,
            payload: null
        });
    }    
}